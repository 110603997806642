body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.secureheader {
  min-height: 5vh;
  max-height: 5vh;
}

.securefooter {
  min-height: 5vh;
  max-height: 5vh;
}

.maincontent {
  min-height: 90vh;
  max-height: 90vh;
}

.loading {
  position: absolute;
  top: 40%;
  left: 40%;
}

.loading img {
  width: 20%;
  height: auto;
}

.scrollContent {
  overflow: auto;
  height: 90vh;
}

.content {
  min-height: 84vh;
}

.text-right {
  text-align: right !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.modal {
  display: block !important;
  background: rgba(100, 100, 100, 0.8);
}

.classes-modal {
  width: 60% !important;
  margin: 20vh 20% !important;
  max-width: 60% !important;
}

/* .modal-login {
  top: 20%;
  background-color: #61dafb;
}

.modal-dialog-login {
  background-color: cadetblue;
  width: 40% !important;
  margin: 10vh 30% !important;
  max-width: 40% !important;
} */

.full-screen-print {
  position: absolute;
  width: 100%;
  height: 130vh;
  z-index: 1111111111;
  top: 0px;
  left: 0px;
  background: white;
}

.certi-header {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}

.footer-bottom {
}

.seal {
  position: absolute;
  left: 170px;
  top: 820px;
}

@media print {
  .on-print {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }

  .footer-bottom {
    position: fixed;
    bottom: 0px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.button-container>* {
  margin: 0 10px;
}


@media (max-width: 768px) {
  #right-panel {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .right-panel .menutoggle {
      float: none !important;
  }
}

/* Menubar Responsiveness */
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.menutoggle {
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  display: none;
}

.menutoggle i.fa-bars {
  color: #ffffff;
  font-size: 20px;
}

@media (max-width: 768px) {
  .menutoggle {
    display: inline-block;
  }

  .menutoggle i.fa-bars {
    color: #03228f;
  }
}

.popup-window {
  position: absolute;
  top: 115px;
  left: 0;
  width: 275px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 0 10px 10px 0;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-item {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.popup-item a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.popup-item:hover {
  background-color: #f0f0f0;
} */


.print-container-certi-design {
  border: 20px solid #ff0000;
  height: 100vh;
  font-size: 1.1rem;
  border-image: linear-gradient(to right, #3acfd5 0%, #41f098 100%) 1;
  border-radius: 5px; /* this doesn't work */
  border-width: 20px;
  border-style: solid;
  background: #d9f4e7;
  print-color-adjust: exact;
  /* background: url('./images/bgtexture1.jpg');
  background: url('./images/bgtexture2.png');
  background: url('./images/bgtexture3.avif'); */
  background: url('./images/bgtexture4.jpg');
  background-size: cover;
}

/* .print-container-certi-design strong {
  font-size: 1.3rem;
} */

.print-container-content-certi-design {
  background: rgba(255, 255, 255, 0.7);
  height: calc(100vh - 40px);
  padding: 30px;
}

.logo-3 {
  height: 90px;
  width: auto;
}